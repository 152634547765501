<script>
import { mapActions } from 'vuex'

export default {
  name: 'Ranking',
  components: {
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination')
  },

  data () {
    return {
      playersList: [],
      pagination: {
        page: 1
      }
    }
  },

  computed: {
    pageItems () {
      return this.playersList.slice((this.pagination.page - 1) * 12, ((this.pagination.page - 1) * 12) + 12)
    }
  },

  created () {
    this.setFetching(true)

    Promise.all([ this.getPlayersList() ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'attemptGetRankingPlayersList'
    ]),

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.getLastPage
    },

    nextPage () {
      if (this.pagination.page < this.getLastPage) {
        this.pagination.page += 1
      }
    },

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    getPlayersList () {
      return this.attemptGetRankingPlayersList({}).then(result => {
        if (result.data) {
          result.data.forEach(element => {
            this.playersList.push({
              position: element.metadata.position,
              name: element.name,
              points: element.metadata.totalPoints
            })
          })

          return true
        }
      })
    },

    getLastPage () {
      return this.countPages(this.playersList.length, 12)
    }
  }
}
</script>

<template>
  <div
    v-if="pageItems.length > 0"
    class="center ranking-table"
  >
    <Datatable
      :class="{'first-page': pagination.page === 1}"
      :items="pageItems"
    >
      <template slot="thead">
        <tr>
          <th class="th">
            {{ $t('ranking.list:datatable.header.1') }}
          </th>
          <th class="th">
            {{ $t('ranking.list:datatable.header.2') }}
          </th>
          <th class="th">
            {{ $t('ranking.list:datatable.header.3') }}
          </th>
        </tr>
      </template>
      <template
        slot="tbody"
        slot-scope="props"
      >
        <tr>
          <td class="td">
            <span class="td-text">{{ props.item.position }}º</span>
          </td>
          <td class="td">
            <span class="td-text">{{ props.item.name }}</span>
          </td>
          <td class="td">
            <span class="td-text">{{ props.item.points }}</span>
          </td>
        </tr>
      </template>
    </Datatable>
    <p class="ranking-statement-obs">
      **Exibindo os 100 primeiros colocados.
    </p>
    <Pagination
      :active-page="pagination.page"
      :page-count="getLastPage"
      @firstPage="firstPage"
      @lastPage="lastPage"
      @nextPage="nextPage"
      @previousPage="prevPage"
    />
  </div>
</template>

<style>
.ranking-table .datatable .td:nth-child(1) {
  padding: 16px 30px;
}
.ranking-table .datatable-wrapper.first-page .datatable tr:nth-child(1) .td,
.ranking-table .datatable-wrapper.first-page .datatable tr:nth-child(2) .td,
.ranking-table .datatable-wrapper.first-page .datatable tr:nth-child(3) .td {
  font-weight: bold;
}

.ranking-statement-obs {
  margin-top: 10px;
  margin-left: 20px;
  font-size: 12px;
  font-style: italic;
  color: #000;
  opacity: 0.55;
}
</style>
